.App {
  text-align: center;
}

.irecipe {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding : 2vmin;
}

.recipes {
  display: flex;
  flex-direction: column;
  width: 75vw;
  height: 90vh;
  padding: 2vmin;
  margin-left: 1vmin;
}

.recipe-control-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.status-message {
  background-color: lightgreen;
  color: black;
}

.recipe-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 5vh;
}

.recipe-control-item {
  color: steelblue;
  background-color: white;
  padding: 1.5vmin;
  margin-right: 1vmin;
  border: 1px solid steelblue;
}

.recipe-control-item:hover {
  color: white;
  background-color: steelblue;
}

.recipe-viewer {
  height: 85vh;
  width: 100%;
  border: 1px solid gainsboro;
  border-radius: 0;
  margin-top: 1vh;
}

.double-part {
  border: 1px solid black;
  width: 72vw;
  overflow-y: auto;
  margin-top: 1vmin;
  height: 24vh;
}

.recipe-part {
  border: 1px solid black;
  width: 72vw;
  overflow-y: auto;
  margin-top: 1vmin;
  height: 12vh;
}

.search-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.search-input {
  height: 5vh;
  width: 100%;
  border: 1px solid gainsboro;
  border-radius: 0;
}

.search-refresh {
  margin-left: 1vmin;
  padding: 1vmin;
  color: steelblue;
  background-color: white;
}

.search-refresh:hover {
  color: white;
  background-color: steelblue;
}

.recipe-list-item {
  background-color: steelblue;
  color: white;
  padding: 1vmin;
  border: 1px solid white;
}

.search {
  display: flex;
  flex-direction: column;
  width: 20vw;
  height: 90vh;
  padding: 2vmin;
}

.recipe-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 1vw;
}

.header-toggle.react-toggle-track {
  background-color: white;
}

.header-toggle.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: steelblue;
}

.header-toggle.react-toggle--checked .react-toggle-track {
  background-color: dodgerblue;
}
  
.header-toggle.react-toggle-thumb {
  border: 1px solid #4D4D4D;
  background-color: #FAFAFA;
}

.header-toggle.react-toggle--checked .react-toggle-thumb {
  border-color: white;
}

.header-toggle-label {
  margin-left: 1vw;
}

.checklist {
  display: flex;
  flex-direction: column;
  height: 24vh;
  width: 72vw;
}

.checklist-item-check {
  background-color: lightgreen;  
  padding: 1vmin;
}

.checklist-item-unchecked {
  background-color: white;
  padding: 1vmin;
}

.search-results {
  margin-top: 1vh;
  overflow-y: auto;
}

.tab-list {
  margin: 0 0 0.5vmin;
  padding: 0;
}

.camera-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1vmin;
}

.camera-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1vmin;
}

.camera-button {
  margin-top: 1vmin;
  border: 0px;
  border-radius: 0px;
  padding: 1vmin;
  color: white;
  background-color: steelblue;
}

.camera-thumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 45vh;
  overflow-y: auto;
}

.camera-thumb {
  padding: 1vmin;
}

.thumb {
  height: 150px;
  width: 200px;
}

.thumb-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.image-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1vmin;
}

.image-view {
  width: 72vmin;
  height: 54vmin;
}

.clip-view {
  width: 36vmin;
  height: 27vmin;
}

.image-item {
  width: 100%;
  height: 100%;
}

.image-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1vmin;
}

.image-thumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 25vh;
  overflow-y: auto;
}

.image-thumb {
  padding: 1vmin;
  height: 150px;
  width: 200px;
}

.recipe-html {
  height: 80vh;
  width: 74vw;
  overflow-y: auto;
}

.recipe-pdf {
  height: 80vh;
  width: 74vw;
}

.recipe-tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 0.5vmin 1vmin;
  cursor: pointer;
  color: steelblue;
  background-color: white;
}

.recipe-paragraph {
  margin-top: 1vmin;
  border: 1px solid transparent;
}

.recipe-tab-selected {
  color: white;
  background-color: steelblue;
}

.file-tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.file-drag-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: steelblue;
  background-color: gainsboro;
  margin-bottom: 0.5vmin;
  padding: 0.25vmin;
}

.file-item {
  width: 40vmin;
  height: 30vmin;
  padding: 1vmin;
  margin: 1vmin;
  border: 1px solid black;
}

.file-name {
  color: white;
  background: steelblue;
  padding: 0.5vmin;
  margin-bottom: 1vmin;
}

.file-tabname {
  padding: 0.5vmin;
}

@media (max-width: 767.9px) {
  .irecipe {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2vmin;
  }

  .search {
    display: flex;
    flex-direction: column;
    height: 20vh;
    width: 90vw;
    padding: 2vmin;
  }

  .search-panel {
    align-items: center;
    justify-content: space-between;
    height: 7vh;
  }

  .search-input {
    height: 5vh;
    width: 100%;
    border: 1px solid gainsboro;
    border-radius: 0;
  }

  .search-refresh {
    margin-left: 1vmin;
    padding: 1vmin;
    color: steelblue;
    background-color: white;
  }


  .search-results {
    height: 13vh;
    margin-top: 1vh;
    overflow-y: auto;
  }

  .search-refresh:hover {
    color: white;
    background-color: steelblue;
  }

  .recipes {
    display: flex;
    flex-direction: column;
    height: 75vh;
    width: 90vw;
    margin-top: 1vmin;
  }

  .recipe-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 5vh;
    margin-bottom: 1vmin;
  }

  .recipe-viewer {
    height: 65vh;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 0;
    margin-top: 1vh;
  }

  .image-thumbs {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 25vh;
    overflow-y: auto;
  }
  
  .image-thumb {
    margin-top: 5vmin;
    padding: 1vmin;
    height: 150px;
    width: 200px;
  }
}
